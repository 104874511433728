import React, { useEffect, useState } from 'react';
import Menu from '../menu/Menu';
import { FaSpinner } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import useAuth from '../services/useAuth';

function ListProduto() {
    useAuth();

    const [produtos, setProdutos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(location.state && location.state.successMessage);

    useEffect(() => {
        axiosInstance.get("/produtos/")
            .then(result => {
                if (Array.isArray(result.data)) {
                    setProdutos(result.data);
                } else {
                    console.error('API response is not an array:', result.data);
                    setErrorMessage('Erro ao carregar produtos. Por favor, tente novamente mais tarde.');
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar Produto:', error);
                setErrorMessage('Erro ao carregar produtos. Por favor, tente novamente mais tarde.');
                setIsLoading(false);
            });

        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }

        if (showDeleteMessage) {
            const timer = setTimeout(() => {
                setShowDeleteMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, showDeleteMessage]);

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const deletarProduto = (id) => {
        const isConfirmed = window.confirm('Tem certeza que deseja excluir este produto?');

        if (isConfirmed) {
            axiosInstance.delete(`/produtos/${id}`)
                .then(() => {
                    setProdutos(produtos.filter(produto => produto.id !== id));
                    setShowDeleteMessage(true);
                })
                .catch(error => {
                    console.error('Erro ao deletar produto:', error);
                });
        } else {
            console.log('Exclusão cancelada');
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Produtos</h4>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {successMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <Link to={"/produto/cadastro"}>
                            <button className='btn btn-success'>Adicionar Produto</button>
                        </Link>
                        {errorMessage && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {showDeleteMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Produto removido com sucesso!
                            </div>
                        )}
                        <hr />
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <FaSpinner className="mr-2" size={20} color="#007bff" /> Carregando...
                            </div>
                        ) : produtos.length === 0 ? (
                            <div>Não há produtos cadastrados.</div>
                        ) : (
                            <div className="row">
                                {produtos.map(produto => (
                                    <div key={produto.id} className="col-md-4 mb-4">
                                        <div className="card" style={{ position: 'relative', minHeight: '250px' }}>
                                            <div className="card-body">
                                                <h5 className="card-title">{produto.nome}</h5>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListProduto;
