import React, { useState, useEffect, useRef } from "react";
import Menu from "../menu/Menu";
import axiosInstance from "../services/axiosInstance";
import { NumericFormat } from "react-number-format";
import useAuth from "../services/useAuth";
import { useNavigate } from "react-router-dom";

function CreateProduto() {
  useAuth();
  const navigate = useNavigate();

  const [nomeProduto, setNomeProduto] = useState("");
  const [categoriaProduto, setCategoriaProduto] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [receitas, setReceitas] = useState([]);
  const [receitasSelecionadas, setReceitasSelecionadas] = useState([]);
  const [novaReceita, setNovaReceita] = useState("");
  const [novoIngrediente, setNovoIngrediente] = useState("");
  const [novaEmbalagem, setNovaEmbalagem] = useState("");
  const [novaDecoracao, setNovaDecoracao] = useState("");
  const [tempoExecucao, setTempoExecucao] = useState("");
  const [embalagensSelecionadas, setEmbalagensSelecionadas] = useState([]);
  const [ingredientesDisponiveis, setIngredientesDisponiveis] = useState([]);
  const [ingredientesSelecionados, setIngredientesSelecionados] = useState([]);
  const [decoracoesSelecionadas, setDecoracoesSelecionadas] = useState([]);
  const [quantidadeGramas, setQuantidadeGramas] = useState("");
  const [quantidadeEmbalagem, setQuantidadeEmbalagem] = useState("");
  const [quantidadeDecoracao, setQuantidadeDecoracao] = useState("");
  const [sufixoIngrediente, setSufixoIngrediente] = useState("");
  const [sufixoEmbalagem, setSufixoEmbalagem] = useState("");
  const [sufixoDecoracao, setSufixoDecoracao] = useState("");
  const [embalagens, setEmbalagens] = useState([]);
  const [tempoDecoracaoEmbalagem, setTempoDecoracaoEmbalagem] = useState("");
  const [custoAcabamentos, setCustoAcabamentos] = useState(0);
  const [custosIngredientes, setCustosIngredientes] = useState(0);
  const [custosEmbalagens, setCustosEmbalagens] = useState(0);
  const [custoTotal, setCustoTotal] = useState(0);
  const [precoVenda, setPrecoVenda] = useState("");
  const [margemContribuicao, setMargemContribuicao] = useState(45);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [nomeNovaCategoria, setNomeNovaCategoria] = useState("");

  useEffect(() => {
    fetchDadosIniciais();
  }, []);

  useEffect(() => {
    adicionarSufixoIngrediente();
  }, [novoIngrediente]);

  useEffect(() => {
    adicionarSufixoEmbalagem();
  }, [novaEmbalagem]);

  useEffect(() => {
    adicionarSufixoDecoracao();
  }, [novaDecoracao]);

  const fetchDadosIniciais = () => {
    axiosInstance
      .get("/receitas/")
      .then((result) => {
        setReceitas(result.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar receitas:", error);
        setErrorMessage(
          "Erro ao carregar receitas. Por favor, tente novamente mais tarde."
        );
      });

    axiosInstance
      .get("/materiaprima/")
      .then((result) => {
        setIngredientesDisponiveis(result.data);
        setEmbalagens(result.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar materiasPrima:", error);
        setErrorMessage(
          "Erro ao carregar materiasPrima. Por favor, tente novamente mais tarde."
        );
      });

    axiosInstance
      .get("/categoriaProduto/")
      .then((result) => {
        setCategorias(result.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar categorias:", error);
        setErrorMessage(
          "Erro ao carregar categorias. Por favor, tente novamente mais tarde."
        );
      });
  };

  const adicionarReceita = () => {
    if (novaReceita !== "") {
      const receitaSelecionada = receitas.find(
        (receita) => receita.nome === novaReceita
      );
      if (receitaSelecionada) {
        setReceitasSelecionadas([...receitasSelecionadas, receitaSelecionada]);
        setNovaReceita("");
      }
    }
  };

  const adicionarIngrediente = () => {
    if (novoIngrediente !== "" && quantidadeGramas !== "") {
      const ingredienteSelecionado = ingredientesDisponiveis.find(
        (ingrediente) => ingrediente.nome === novoIngrediente
      );

      if (ingredienteSelecionado) {
        const novoCustoEstimado = calcularCustoIngrediente(
          novoIngrediente,
          quantidadeGramas
        );

        setIngredientesSelecionados([
          ...ingredientesSelecionados,
          {
            nome: novoIngrediente,
            quantidade: quantidadeGramas,
            unidadeMedida: sufixoIngrediente,
            custoEstimado: novoCustoEstimado,
          },
        ]);

        setCustosIngredientes((prevCusto) => prevCusto + novoCustoEstimado);

        setNovoIngrediente("");
        setQuantidadeGramas("");
      }
    }
  };

  const adicionarEmbalagem = () => {
    if (novaEmbalagem && quantidadeEmbalagem) {
      const embalagemSelecionada = embalagensDisponiveis.find(
        (ingrediente) => ingrediente.nome === novaEmbalagem
      );
      if (embalagemSelecionada) {
        const novoCustoEstimado = calcularCustoIngrediente(
          novaEmbalagem,
          quantidadeEmbalagem
        );

        setEmbalagensSelecionadas([
          ...embalagensSelecionadas,
          {
            ...embalagemSelecionada,
            quantidade: quantidadeEmbalagem,
            custoEstimado: novoCustoEstimado,
          },
        ]);
        setNovaEmbalagem("");
        setQuantidadeEmbalagem("");
      }
    }
  };

  const adicionarDecoracao = () => {
    if (novaDecoracao && quantidadeDecoracao) {
      const decoracaoSelecionada = decoracoesDisponiveis.find(
        (ingrediente) => ingrediente.nome === novaDecoracao
      );
      if (decoracaoSelecionada) {
        const novoCustoEstimado = calcularCustoIngrediente(
          novaDecoracao,
          quantidadeDecoracao
        );
        setDecoracoesSelecionadas([
          ...decoracoesSelecionadas,
          {
            ...decoracaoSelecionada,
            quantidade: quantidadeDecoracao,
            custoEstimado: novoCustoEstimado,
          },
        ]);
        setNovaDecoracao("");
        setQuantidadeDecoracao("");
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!nomeProduto || !categoriaProduto) {
      setErrorMessage("Por favor, preencha o nome e a categoria do produto.");
      scrollToTopOnError();
      return;
    }

    const produtoData = {
      nome: nomeProduto,
      categoria: { id: categoriaProduto },
      receitas: receitasSelecionadas,
      ingredientes: ingredientesSelecionados,
      embalagens: embalagensSelecionadas,
      decoracoes: decoracoesSelecionadas,
      tempoDecoracaoEmbalagem,
      custoAcabamentos,
      custoTotal,
      precoVenda,
      margemContribuicao,
    };

    axiosInstance
      .post("/produtos", produtoData)
      .then((response) => {
        setShowSuccessMessage(true);
        setNomeProduto("");
        setCategoriaProduto("");
        setReceitasSelecionadas([]);
        setIngredientesSelecionados([]);
        setEmbalagensSelecionadas([]);
        setTempoDecoracaoEmbalagem("");
        setCustoAcabamentos(0);
        setCustoTotal(0);
        setPrecoVenda("");
        setMargemContribuicao(45);
        navigate("/produto/listar", {
          state: { successMessage: "Produto cadastrado com sucesso!" },
        });
      })
      .catch((error) => {
        console.error("Erro ao salvar o produto:", error);
        setErrorMessage(
          "Erro ao salvar o produto. Por favor, tente novamente mais tarde."
        );
      });
  };

  const submeterNovaCategoria = (e) => {
    e.preventDefault();

    const novaCategoriaData = {
      nome: nomeNovaCategoria,
    };

    axiosInstance
      .post("/categoriaProduto", novaCategoriaData)
      .then((response) => {
        setIsPopupOpen(false);
        setNomeNovaCategoria("");
        fetchCategoriasAtualizadas();
      })
      .catch((error) => {
        console.error("Erro ao adicionar a nova categoria:", error);
        setErrorMessage(
          "Erro ao adicionar a nova categoria. Por favor, tente novamente mais tarde."
        );
      });
  };

  const fetchCategoriasAtualizadas = () => {
    axiosInstance
      .get("/categoriaProduto/")
      .then((result) => {
        setCategorias(result.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar categorias:", error);
        setErrorMessage(
          "Erro ao carregar categorias. Por favor, tente novamente mais tarde."
        );
      });
  };

  const topRef = useRef(null);

  const scrollToTopOnError = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const removerReceita = (index) => {
    const novasReceitasSelecionadas = receitasSelecionadas.filter(
      (_, i) => i !== index
    );
    setReceitasSelecionadas(novasReceitasSelecionadas);
  };

  const removerIngrediente = (index) => {
    const novosIngredientesSelecionados = ingredientesSelecionados.filter(
      (_, i) => i !== index
    );
    setIngredientesSelecionados(novosIngredientesSelecionados);
  };

  const removerEmbalagem = (index) => {
    const novasEmbalagensSelecionadas = embalagensSelecionadas.filter(
      (_, i) => i !== index
    );
    setEmbalagensSelecionadas(novasEmbalagensSelecionadas);
  };

  const removerDecoracao = (index) => {
    const novasDecoracoesSelecionadas = decoracoesSelecionadas.filter(
      (_, i) => i !== index
    );
    setDecoracoesSelecionadas(novasDecoracoesSelecionadas);
  };

  const somenteIngredientes = ingredientesDisponiveis
    .filter((materiaPrima) => materiaPrima.tipo === "Ingrediente")
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const embalagensDisponiveis = embalagens
    .filter((materiaPrima) => materiaPrima.tipo === "Embalagem")
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const decoracoesDisponiveis = embalagens
    .filter((materiaPrima) => materiaPrima.tipo === "Decoracao")
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const adicionarSufixoIngrediente = () => {
    const ingredienteSelecionado = ingredientesDisponiveis.find(
      (ingrediente) => ingrediente.nome === novoIngrediente
    );

    if (ingredienteSelecionado) {
      switch (ingredienteSelecionado.unidadeMateriaPrima) {
        case "kg":
        case "g":
          setSufixoIngrediente(" gramas");
          break;
        case "ml":
        case "L":
          setSufixoIngrediente(" ml");
          break;
        case "unid.":
          setSufixoIngrediente(" unid.");
          break;
        case "m":
        case "cm":
          setSufixoIngrediente(" cm");
          break;
        default:
          setSufixoIngrediente("");
          break;
      }
    } else {
      setSufixoIngrediente("");
    }
  };

  const adicionarSufixoEmbalagem = () => {
    const embalagemSelecionada = embalagens.find(
      (ingrediente) => ingrediente.nome === novaEmbalagem
    );
    if (embalagemSelecionada) {
      switch (embalagemSelecionada.unidadeMateriaPrima) {
        case "kg":
        case "g":
          setSufixoEmbalagem(" gramas");
          break;
        case "ml":
        case "L":
          setSufixoEmbalagem(" ml");
          break;
        case "unid.":
          setSufixoEmbalagem(" unid.");
          break;
        case "m":
        case "cm":
          setSufixoEmbalagem(" cm");
          break;
        default:
          setSufixoEmbalagem("");
          break;
      }
    } else {
      setSufixoEmbalagem("");
    }
  };

  const calcularCustoIngrediente = (ingrediente, quantidadePorEmbalagem) => {
    const selectedMateriaPrima = ingredientesDisponiveis.find(
      (mP) => mP.nome === ingrediente
    );
    if (selectedMateriaPrima) {
      const quantidadeEmGramas = unidadeParaGramas(
        selectedMateriaPrima.unidadeMateriaPrima,
        quantidadePorEmbalagem
      );
      const custoPorGrama =
        selectedMateriaPrima.valorUnitario /
        selectedMateriaPrima.quantidadePorEmbalagem;
      const custo = quantidadeEmGramas * custoPorGrama;
      return custo;
    }

    return 0;
  };

  const unidadeParaGramas = (unidade, quantidade) => {
    const quantidadeNumerica = parseFloat(quantidade);
    switch (unidade) {
      case "kg":
        return quantidadeNumerica * 1000;
      case "g":
        return quantidadeNumerica;
      case "L":
        return quantidadeNumerica * 1000;
      case "ml":
        return quantidadeNumerica;
      default:
        return quantidadeNumerica;
    }
  };

  const adicionarSufixoDecoracao = () => {
    const decoracaoSelecionada = embalagens.find(
      (ingrediente) => ingrediente.nome === novaDecoracao
    );
    if (decoracaoSelecionada) {
      switch (decoracaoSelecionada.unidadeMateriaPrima) {
        case "kg":
        case "g":
          setSufixoDecoracao(" gramas");
          break;
        case "ml":
        case "L":
          setSufixoDecoracao(" ml");
          break;
        case "unid.":
          setSufixoDecoracao(" unid.");
          break;
        case "m":
        case "cm":
          setSufixoDecoracao(" cm");
          break;
        default:
          setSufixoDecoracao("");
          break;
      }
    } else {
      setSufixoDecoracao("");
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <Menu />
      <div className="layout-container">
        <div ref={topRef} />
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4">Adicionar Produto</h4>
            <div className="row">
              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Dados do Produto</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label
                        className="form-label required"
                        htmlFor="nome-produto"
                      >
                        Nome do Produto
                      </label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        id="nome-produto"
                        name="nome"
                        placeholder="ex.: Bolo de Aniversário"
                        value={nomeProduto}
                        onChange={(e) => setNomeProduto(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="form-label required"
                        htmlFor="categoria-produto"
                      >
                        Categoria do Produto
                      </label>
                      <a
                        href="#"
                        style={{ fontSize: "12px", display: "flex" }}
                        onClick={() => setIsPopupOpen(true)}
                      >
                        Não tem a categoria desejada? Clique aqui.
                      </a>
                      <select
                        className="form-control mt-2"
                        id="categorias-existentes"
                        name="categoriasExistentes"
                        value={categoriaProduto}
                        onChange={(e) => setCategoriaProduto(e.target.value)}
                      >
                        <option value="">
                          Selecione uma categoria existente
                        </option>
                        {categorias.map((categoria) => (
                          <option key={categoria.id} value={categoria.id}>
                            {categoria.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header flex-direction-row align-items-center">
                    <h5 className="mb-0">Receitas</h5>
                    <a
                      href="#"
                      style={{ fontSize: "12px" }}
                      onClick={() => setIsPopupOpen(true)}
                    >
                      Sua receita não está na lista? Clique aqui.
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-type"
                      >
                        Receitas
                      </label>
                      <select
                        className="form-control"
                        id="basic-default-type"
                        name="tipo"
                        value={novaReceita}
                        onChange={(e) => setNovaReceita(e.target.value)}
                      >
                        <option value="">Selecione uma receita</option>
                        {receitas.map((receita) => (
                          <option key={receita.id} value={receita.nome}>
                            {receita.nome}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        className="btn btn-outline-primary mt-3"
                        onClick={adicionarReceita}
                      >
                        Adicionar Receita
                      </button>
                    </div>
                    {receitasSelecionadas.length > 0 && (
                      <>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="selected-receitas"
                          >
                            Receitas Selecionadas:
                          </label>
                          <ul>
                            {receitasSelecionadas.map((receita, index) => (
                              <li key={index}>
                                {receita.nome} (
                                {receita.custoEstimado
                                  ? `R$ ${receita.custoEstimado
                                      .toFixed(2)
                                      .replace(".", ",")}`
                                  : "Indisponível"}
                                )
                                <button
                                  type="button"
                                  className="btn btn-outline-danger ms-2"
                                  onClick={() => removerReceita(index)}
                                >
                                  Remover
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="mb-3">
                          <strong>
                            Custo Total de Receitas: R$
                            {receitasSelecionadas
                              .reduce((total, receita) => {
                                return total + (receita.custoEstimado || 0);
                              }, 0)
                              .toFixed(2)
                              .replace(".", ",")}
                          </strong>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header flex-direction-row align-items-center">
                    <h5 className="mb-0">Adicionar Ingrediente Avulso</h5>
                    <a
                      href="#"
                      style={{ fontSize: "12px" }}
                      onClick={() => setIsPopupOpen(true)}
                    >
                      Não tem o ingrediente que precisa? Clique aqui.
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-type"
                      >
                        Ingredientes
                      </label>
                      <select
                        className="form-control"
                        id="basic-default-type"
                        name="tipo"
                        value={novoIngrediente}
                        onChange={(e) => setNovoIngrediente(e.target.value)}
                      >
                        <option value="">Selecione um ingrediente</option>
                        {somenteIngredientes.map((ingrediente) => (
                          <option
                            key={ingrediente.nome}
                            value={ingrediente.nome}
                          >
                            {ingrediente.nome} - {ingrediente.marca}
                          </option>
                        ))}
                      </select>
                    </div>

                    {novoIngrediente && (
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-type"
                        >
                          Quantidade a ser utilizada
                        </label>
                        <NumericFormat
                          className="form-control"
                          id="basic-default-fullname"
                          name="quantidade"
                          value={quantidadeGramas}
                          onChange={(e) => setQuantidadeGramas(e.target.value)}
                          allowNegative={false}
                          decimalScale={2}
                          placeholder={`ex.: 100${sufixoIngrediente}`}
                          suffix={sufixoIngrediente}
                          required
                        />
                      </div>
                    )}

                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary mt-3"
                        onClick={adicionarIngrediente}
                      >
                        Adicionar Ingrediente
                      </button>
                    </div>

                    {ingredientesSelecionados.length > 0 ? (
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="selected-ingredientes"
                        >
                          Ingredientes Selecionados:
                        </label>
                        <ul>
                          {ingredientesSelecionados.map(
                            (ingrediente, index) => (
                              <li key={index}>
                                {ingrediente.nome} - {ingrediente.quantidade} -
                                R$
                                {ingrediente.custoEstimado
                                  ? ingrediente.custoEstimado
                                      .toFixed(2)
                                      .replace(".", ",")
                                  : "0,00"}
                                <button
                                  type="button"
                                  className="btn btn-outline-danger ms-2"
                                  onClick={() => removerIngrediente(index)}
                                >
                                  Remover
                                </button>
                              </li>
                            )
                          )}
                        </ul>
                        <div className="mt-3">
                          <strong>Custo Total de Ingredientes Avulsos:</strong>{" "}
                          R$
                          {ingredientesSelecionados
                            .reduce((total, ingrediente) => {
                              return total + (ingrediente.custoEstimado || 0);
                            }, 0)
                            .toFixed(2)
                            .replace(".", ",")}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header flex-direction-row align-items-center">
                    <h5 className="mb-0">Adicionar Embalagem</h5>
                    <a
                      href="#"
                      style={{ fontSize: "12px" }}
                      onClick={() => setIsPopupOpen(true)}
                    >
                      Não achou o item que precisa? Clique aqui.
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-type"
                      >
                        Embalagem
                      </label>
                      <select
                        className="form-control"
                        id="basic-default-type"
                        name="tipo"
                        value={novaEmbalagem}
                        onChange={(e) => setNovaEmbalagem(e.target.value)}
                      >
                        <option value="">Selecione uma embalagem</option>
                        {embalagensDisponiveis.map((embalagem) => (
                          <option key={embalagem.nome} value={embalagem.nome}>
                            {embalagem.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                    {novaEmbalagem && (
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-type"
                        >
                          Quantidade a ser utilizada
                        </label>
                        <NumericFormat
                          className="form-control"
                          id="basic-default-fullname"
                          name="quantidade"
                          value={quantidadeEmbalagem}
                          onChange={(e) =>
                            setQuantidadeEmbalagem(e.target.value)
                          }
                          allowNegative={false}
                          decimalScale={2}
                          placeholder={`ex.: 100${sufixoEmbalagem}`}
                          suffix={sufixoEmbalagem}
                          required
                        />
                      </div>
                    )}
                    <div className="mb-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary mt-3"
                        onClick={adicionarEmbalagem}
                      >
                        Adicionar Embalagem
                      </button>
                    </div>

                    {embalagensSelecionadas.length > 0 ? (
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="selected-ingredientes"
                        >
                          Embalagens Selecionadas:
                        </label>
                        <ul>
                          {embalagensSelecionadas.map((embalagem, index) => (
                            <li key={index}>
                              {embalagem.nome} - {embalagem.quantidade}
                              {embalagem.unidadeMedida} - R$
                              {embalagem.custoEstimado
                                ? embalagem.custoEstimado
                                    .toFixed(2)
                                    .replace(".", ",")
                                : "0,00"}
                              <button
                                type="button"
                                className="btn btn-outline-danger ms-2"
                                onClick={() => removerEmbalagem(index)}
                              >
                                Remover
                              </button>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-3">
                          <strong>Custo Total de Embalagens:</strong> R$
                          {embalagensSelecionadas
                            .reduce((total, embalagem) => {
                              return total + (embalagem.custoEstimado || 0);
                            }, 0)
                            .toFixed(2)
                            .replace(".", ",")}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header flex-direction-row align-items-center">
                    <h5 className="mb-0">Adicionar Decoração</h5>
                    <a
                      href="#"
                      style={{ fontSize: "12px" }}
                      onClick={() => setIsPopupOpen(true)}
                    >
                      Não achou o item que precisa? Clique aqui.
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-type"
                      >
                        Decoração
                      </label>
                      <select
                        className="form-control"
                        id="basic-default-type"
                        name="tipo"
                        value={novaDecoracao}
                        onChange={(e) => setNovaDecoracao(e.target.value)}
                      >
                        <option value="">Selecione uma decoração</option>
                        {decoracoesDisponiveis.map((decoracao) => (
                          <option key={decoracao.nome} value={decoracao.nome}>
                            {decoracao.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                    {novaDecoracao && (
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="basic-default-type"
                        >
                          Quantidade a ser utilizada
                        </label>
                        <NumericFormat
                          className="form-control"
                          id="basic-default-fullname"
                          name="quantidade"
                          value={quantidadeDecoracao}
                          onChange={(e) =>
                            setQuantidadeDecoracao(e.target.value)
                          }
                          allowNegative={false}
                          decimalScale={2}
                          placeholder={`ex.: 100${sufixoDecoracao}`}
                          suffix={sufixoDecoracao}
                          required
                        />
                      </div>
                    )}
                    <div className="mt-4">
                      <button
                        type="button"
                        className="btn btn-outline-primary mt-3"
                        onClick={adicionarDecoracao}
                      >
                        Adicionar Decoração
                      </button>
                    </div>

                    {decoracoesSelecionadas.length > 0 ? (
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="selected-ingredientes"
                        >
                          Decorações Selecionadas:
                        </label>
                        <ul>
                          {decoracoesSelecionadas.map((decoracao, index) => (
                            <li key={index}>
                              {decoracao.nome} - {decoracao.quantidade}
                              {decoracao.unidadeMedida} - R$
                              {decoracao.custoEstimado
                                ? decoracao.custoEstimado
                                    .toFixed(2)
                                    .replace(".", ",")
                                : "0,00"}
                              <button
                                type="button"
                                className="btn btn-outline-danger ms-2"
                                onClick={() => removerDecoracao(index)}
                              >
                                Remover
                              </button>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-3">
                          <strong>Custo Total de Decorações:</strong> R$
                          {decoracoesSelecionadas
                            .reduce((total, decoracao) => {
                              return total + (decoracao.custoEstimado || 0);
                            }, 0)
                            .toFixed(2)
                            .replace(".", ",")}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">
                      Tempo destinado para concluir embalagem e decoração
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="basic-default-prep-time"
                      >
                        Tempo
                      </label>
                      <NumericFormat
                        className="form-control"
                        id="basic-default-prep-time"
                        name="tempoExecucao"
                        placeholder="ex.: 30 minutos"
                        value={tempoExecucao}
                        allowNegative={false}
                        suffix=" minutos"
                        onValueChange={(values) =>
                          setTempoExecucao(values.value)
                        }
                        format="####"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl">
                <div className="card mb-3">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Resumo Final</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <strong>Custo Estimado de Produção: R$</strong>
                      {// Soma dos custos dos ingredientes
                      (
                        receitasSelecionadas.reduce((total, receita) => {
                          return total + (receita.custoEstimado || 0);
                        }, 0) +
                        
                        ingredientesSelecionados.reduce(
                          (total, ingrediente) => {
                            return total + (ingrediente.custoEstimado || 0);
                          },
                          0
                        ) +
                        // Soma dos custos das embalagens
                        embalagensSelecionadas.reduce((total, embalagem) => {
                          return total + (embalagem.custoEstimado || 0);
                        }, 0) +
                        // Soma dos custos das decorações
                        decoracoesSelecionadas.reduce((total, decoracao) => {
                          return total + (decoracao.custoEstimado || 0);
                        }, 0)
                      )
                        .toFixed(2)
                        .replace(".", ",")}
                    </div>
                     <button style={{fontSize: '15px'}} type="submit" className="btn-green">Cadastrar Produto</button>
                  </div>
                 
                </div>
                
              </div>
              
            </div>

            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProduto;
