import React, { useEffect, useState } from 'react';
import Menu from '../menu/Menu';
import { FaSpinner } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import useAuth from '../services/useAuth';

function ListReceita() {
    useAuth();

    const [receitas, setReceitas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(location.state && location.state.successMessage);

    useEffect(() => {
        axiosInstance.get("/receitas/")
            .then(result => {
                if (Array.isArray(result.data)) {
                    setReceitas(result.data);
                } else {
                    console.error('API response is not an array:', result.data);
                    setErrorMessage('Erro ao carregar receitas. Por favor, tente novamente mais tarde.');
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar Receita:', error);
                setErrorMessage('Erro ao carregar receitas. Por favor, tente novamente mais tarde.');
                setIsLoading(false);
            });

        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }

        if (showDeleteMessage) {
            const timer = setTimeout(() => {
                setShowDeleteMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, showDeleteMessage]);

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const deletarReceita = (id) => {
        const isConfirmed = window.confirm('Tem certeza que deseja excluir esta receita?');

        if (isConfirmed) {
            axiosInstance.delete(`/receitas/${id}`)
                .then(() => {
                    setReceitas(receitas.filter(receita => receita.id !== id));
                    setShowDeleteMessage(true);
                })
                .catch(error => {
                    console.error('Erro ao deletar receita:', error);
                });
        } else {
            console.log('Exclusão cancelada');
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Receitas</h4>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {successMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <Link to={"/receita/cadastro"}>
                            <button className='btn btn-success'>Adicionar Receita</button>
                        </Link>
                        {errorMessage && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {showDeleteMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Receita removida com sucesso!
                            </div>
                        )}
                        <hr />
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <FaSpinner className="mr-2" size={20} color="#007bff" /> Carregando...
                            </div>
                        ) : receitas.length === 0 ? (
                            <div>Não há receitas cadastradas.</div>
                        ) : (
                            <div className="row">
                                {receitas.map(receita => (
                                    <div key={receita.id} className="col-md-4 mb-4">
                                        <div className="card" style={{ position: 'relative', minHeight: '250px' }}>
                                            <div className="card-body">
                                                <h5 className="card-title">{receita.nome}</h5>
                                                <hr />
                                                <p className="card-subtitle text-muted mb-3">Categoria: {receita.categoria ? `${receita.categoria}` : 'não fornecido'}</p>
                                                <p className="card-subtitle text-muted mb-3">Custo Estimado: R${receita.custoEstimado.toFixed(2).replace('.', ',')}</p>
                                                <p className="card-subtitle text-muted mb-3">Tempo de Preparo: {receita.tempoPreparo ? `${receita.tempoPreparo} minutos` : 'não fornecido'}</p>
                                                <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                                                    <button className="btn btn-danger" onClick={() => deletarReceita(receita.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListReceita;
