import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'; // Correct import
import styles from './landingpage.module.css';

function LandingPage() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleClientAreaClick = () => {

        window.location.href = '/login'; // Go to login page if not logged in

    };

    const scrollToAboutSection = () => {
        const aboutSection = document.getElementById('about-section');
        if (aboutSection) {
            aboutSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.subHeader}>
                    <div className={styles.logoContainer}>
                        <img height={100} width={100} src={process.env.PUBLIC_URL + '/assets/img/favicon/doceriasmartimg.svg'} alt="Logo da Doceria Smart" />
                    </div>
                    <div className={styles.headerButtonContainer}>
                        <a href="#" className={styles.Link} onClick={scrollToAboutSection}>
                            Quem somos?
                        </a>
                        <a href="#" className={styles.Link} onClick={handleClientAreaClick}>
                            <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
                            Área do Cliente
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.secaoFotos}>
                <div className={styles.subSecaoFotos}>
                    <div className={styles.textoFoto}>
                        <div className={styles.bgGradientPrimaryToSecondary}>
                            <div className="text-uppercase">Confeitaria &middot; Inteligente</div>
                        </div>
                        <div className={styles.textoPrincipal}>
                            Venha mergulhar no universo Smart e gerencie sua confeitaria de forma
                        </div>
                        <h1 className="display-3 fw-bolder mb-5">
                            <span className={styles.textGradient}>Inteligente</span>
                        </h1>
                        <button className="btn-green">VENHA SER SMART</button>
                    </div>
                    <div className={styles.profile}>
                        <img
                            className={styles.profileImg}
                            src={`${process.env.PUBLIC_URL}/assets/img/flavia1.jpeg`}
                            alt="First slide"
                        />
                    </div>
                </div>
            </div>

            {/* Add id attribute here */}
            <div id="about-section" className={styles.secaoFotos}>
                <div className={styles.subSecaoFotos}>
                    <div className={styles.textoFoto}>
                        <h1 className="display-3 fw-bolder mb-5">
                            <span className={styles.textGradient}>Quem somos?</span>
                        </h1>
                        <div className={styles.textoPrincipal}>
                            Somos uma marca inovadora no mercado que busca construir um legado na indústria de tecnologia, inserindo nosso produto de qualidade para o cliente, proporcionando uma experiência eficaz.
                        </div>
                    </div>
                    <div className={styles.profile}>
                        <img
                            className={styles.profileImg}
                            src={`${process.env.PUBLIC_URL}/assets/img/flavia2.jpeg`}
                            alt="Second slide"
                        />
                    </div>
                </div>
            </div>

            <div className={styles.secaoFotos}>
                <div className={styles.subSecaoFotos}>
                    <div className={styles.textoFoto}>
                        <div className={styles.textoPrincipal}>
                            Ficou interessado? Gostaria de gerenciar sua confeitaria na palma de sua mão?
                        </div>
                        <h1 className="display-3 fw-bolder mb-5">
                            <span className={styles.textGradient}>Vem conosco!</span>
                        </h1>
                        <button className="btn-green">VENHA SER SMART</button>
                    </div>
                    <div className={styles.profile}>
                        <img
                            className={styles.profileImg}
                            src={`${process.env.PUBLIC_URL}/assets/img/flavia3.jpeg`}
                            alt="Third slide"
                        />
                    </div>
                </div>
            </div>

            <footer style={{ backgroundColor: '#00FF00' }} className="py-4 flex-shrink-0">
                <div className="container px-5">
                    <div style={{ textAlign: 'center' }} className="row align-items-center">
                        <div className="text-white">Doceria Smart</div>
                        <div className="small text-white">&copy; Your Website {new Date().getFullYear()}. All rights reserved.</div>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default LandingPage;
