import React, { useEffect, useState } from 'react';
import Menu from '../menu/Menu.js';
import { NumericFormat } from 'react-number-format';
import axiosInstance from '../services/axiosInstance'; // Importando o axiosInstance configurado
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../services/useAuth';

function CustosFixos() {

    useAuth();

    const [custoFixo, setCustoFixo] = useState({
        nome: '',
        valorCustoFixo: ''
    });
    const [custos, setCustos] = useState([]);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(location.state && location.state.successMessage);
    const [errorHoras, setErrorHoras] = useState(false);
    const [errorDias, setErrorDias] = useState(false);

    // Carrega e salva o valor do pró-labore no armazenamento local
    const [proLabore, setProLabore] = useState(() => {
        const storedValue = localStorage.getItem('proLabore');
        return storedValue !== null ? parseFloat(storedValue) : 0;
    });

    const [diasTrabalhados, setDiasTrabalhados] = useState(() => {
        const storedValue = localStorage.getItem('diasTrabalhados');
        return storedValue !== null ? parseFloat(storedValue) : 0;
    });

    const [horasTrabalhadas, setHorasTrabalhadas] = useState(() => {
        const storedValue = localStorage.getItem('horasTrabalhadas');
        return storedValue !== null ? parseFloat(storedValue) : 0;
    });

    const handleProLaboreChange = (values) => {
        const { value } = values;
        setProLabore(parseFloat(value));
        localStorage.setItem('proLabore', value);
    };

    const handleDiasTrabalhadosChange = (values) => {
        const { value } = values;
        setDiasTrabalhados(parseFloat(value));
        localStorage.setItem('diasTrabalhados', value);

        let numericValue = value;
        if (numericValue < 1 || numericValue > 7) {
            console.log('Fora do intervalo permitido:', numericValue);
            setErrorDias(true);
        } else {
            console.log('Dentro do intervalo permitido:', numericValue);
            setErrorDias(false);
        }
    };

    const handleHorasTrabalhadasChange = (values) => {
        const { value } = values;
        setHorasTrabalhadas(value);
        localStorage.setItem('horasTrabalhadas', value);

        let numericValue = value;
        if (numericValue < 1 || numericValue > 24) {
            console.log('Fora do intervalo permitido:', numericValue);
            setErrorHoras(true);
        } else {
            console.log('Dentro do intervalo permitido:', numericValue);
            setErrorHoras(false);
        }
    };

    useEffect(() => {
        // Carrega a lista de custos fixos quando o componente é montado
        axiosInstance.get("/custofixo/")
            .then(result => {
                setCustos(result.data);
            })
            .catch(error => {
                console.error('Erro ao carregar custos fixos:', error);
            });

        const successTimer = setTimeout(() => {
            setShowSuccessMessage(false);
        }, 5000);

        const deleteTimer = setTimeout(() => {
            setShowDeleteMessage(false);
        }, 5000);

        return () => {
            clearTimeout(successTimer);
            clearTimeout(deleteTimer);
        };
    }, [showSuccessMessage, showDeleteMessage]);

    function handleChange(event) {
        const { name, value } = event.target;
        setCustoFixo(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleSubmit(event) {
        event.preventDefault();
        axiosInstance.post("/custofixo/", custoFixo)
            .then(result => {
                setShowSuccessMessage(true);
                setCustoFixo({
                    nome: '',
                    valorCustoFixo: ''
                });
            })
            .catch(error => {
                console.error('Erro ao cadastrar custo:', error);
            });

        axiosInstance.get("/custofixo/")
            .then(result => {
                setCustos(result.data);
            })
            .catch(error => {
                console.error('Erro ao carregar custos fixos:', error);
            });
    }

    const deletarCustoFixo = (id) => {
        const isConfirmed = window.confirm('Tem certeza que deseja excluir este custo?');
        if (isConfirmed) {
            axiosInstance.delete(`/custofixo/${id}`)
                .then(() => {
                    setCustos(custos.filter(custoFixo => custoFixo.id !== id));
                    setShowDeleteMessage(true);
                })
                .catch(error => {
                    console.error('Erro ao deletar custo fixo:', error);
                });
        }
    };

    const subtotal = custos.reduce((acc, custo) => acc + parseFloat(custo.valorCustoFixo), 0);
    const valorHoraSalario = proLabore / diasTrabalhados;
    const valorHoraCusto = subtotal / proLabore;

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />

            <div className="layout-container">
                <div className="content-wrapper">

                    <div className="container-xxl  container-p-y full-height">
                        <h4 className="py-3 mb-4">Custos Fixos</h4>
                        {showSuccessMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Custo adicionado com sucesso!
                            </div>
                        )}
                        {showDeleteMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Custo removido com sucesso!
                            </div>
                        )}
                        <div className="row">
                            <h5>Resumo: (ainda a ser implementado)</h5>
                            <p>Valor por hora em relação ao salário: </p>
                            <p>Valor por hora em relação a soma de custos: </p>
                            <p>Total de Custos: {subtotal ? `R$ ${subtotal.toFixed(2).replace('.', ',')}` : ''}</p>
                        </div>

                        <div className="row">
                            <div className="col-xl-6">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Horas/Custo</h5>
                                    </div>
                                    <div className="card-body">
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-company">Valor desejado para despesas com Honorário</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    id="basic-default-company"
                                                    name="proLabore"
                                                    value={proLabore}
                                                    onValueChange={handleProLaboreChange}
                                                    placeholder="R$ 0,00"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix="R$ "
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-fullname">Quantidade de Dias Trabalhados na Semana</label>
                                                <NumericFormat
                                                    className={`form-control ${errorDias ? 'is-invalid' : ''}`}
                                                    id="basic-default-company"
                                                    name="diasTrabalhados"
                                                    value={diasTrabalhados}
                                                    onValueChange={handleDiasTrabalhadosChange}
                                                    placeholder="0"
                                                    suffix={diasTrabalhados === '1' ? ' dia' : ' dias'}
                                                />
                                                {errorDias && <div className="invalid-feedback">O valor deve estar entre 1 e 7.</div>}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-fullname">Horas Trabalhadas por dia</label>
                                                <NumericFormat
                                                    className={`form-control ${errorHoras ? 'is-invalid' : ''}`}
                                                    id="basic-default-company"
                                                    name="horasTrabalhadas"
                                                    value={horasTrabalhadas}
                                                    onValueChange={handleHorasTrabalhadasChange}
                                                    placeholder="0"
                                                    suffix={horasTrabalhadas === '1' ? ' hora' : ' horas'}
                                                />
                                                {errorHoras && <div className="invalid-feedback">O valor deve estar entre 1 e 24.</div>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Adicionar Custo</h5>
                                    </div>
                                    <div className="card-body addcusto">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-fullname">Nome</label>
                                                <input type="text" className="form-control" id="basic-default-fullname" name="nome" value={custoFixo.nome} onChange={handleChange} placeholder="ex.: Aluguel" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-company">Valor do Custo</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    id="basic-default-company"
                                                    name="valorCustoFixo"
                                                    value={custoFixo.valorCustoFixo}
                                                    onValueChange={(values) => {
                                                        const { value } = values;
                                                        setCustoFixo(prevState => ({
                                                            ...prevState,
                                                            valorCustoFixo: value
                                                        }));
                                                    }}
                                                    placeholder="R$ 0,00"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix="R$ "
                                                />
                                            </div>
                                            <button style={{fontSize: '15px'}} type="submit" className="btn-green">Cadastrar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4>Seus Custos</h4>

                        {custos.length === 0 ? (
                            <div>Não há custos adicionados</div>
                        ) : (
                            <div className="row">
                                {custos.map(custo => (
                                    <div key={custo.id} className="col-md-4 mb-4">
                                        <div className="card position-relative" style={{ display: 'flex', flexDirection: 'column', height: '90%', width: '100%' }}>
                                            <div className="card-body">
                                                <h5 className="card-title">{custo.nome}</h5>
                                                <p className="card-subtitle text-muted mb-3">{custo.valorCustoFixo ? `R$ ${custo.valorCustoFixo.toFixed(2).replace('.', ',')}` : ''}</p>
                                            </div>
                                            <div className="position-absolute bottom-0 end-0 p-3">
                                                <FontAwesomeIcon icon={faTrash} className="text-danger" onClick={() => deletarCustoFixo(custo.id)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustosFixos;
